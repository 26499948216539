@use './scss/fonts.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

html,
body {
    overflow: hidden;
}

body, .main {
  // background: url(../public/img/proton-bg.jpg) no-repeat center/cover fixed;
  background-color: black;
}

body * {
    font-family: SF Pro Display !important;
    box-sizing: border-box;
}

.page-wrapper{
    position: absolute;
    overflow: auto;
    overflow-x: hidden;
    top: 0;left: 0;
    width: 100%;
    height: calc(100vh - 75px);
    padding-bottom: 15px;
}

button{
  background-color: transparent;
  border: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

button .button-blockchain {
  color: #FFFFFF99;
}
